<template>
  <div class="register loginPageBox">
    <div class="contentBox">
      <h2 class="loginTitle">
        采金链系统
      </h2>
      <p class="loginDescription">
        整合上游供应链资源，构建互联网+传统产业
      </p>
      <div class="form">
        <div class="form-left">
          <img class="logo" :src="$localPicture+'logo/logo_white.png'" alt="">
          <div class="graphical" />
          <canvas id="canvas" class="canvas" />
        </div>
        <div class="form-right" :class="{ 'reigster-form': loginOrRegister }">
          <!-- 上面可点击的tab -->
          <div class="titleTab">
            <span
              v-for="(item, index) in loginTabArr"
              :key="index"
              :class="activeIndex === index ? 'active' : ''"
              @click="getActiveIndex(index)"
            >{{ item }}</span>
          </div>
          <!-- 这是登录的 -->
          <el-form
            ref="ruleForm"
            :model="loginFrom"
            :rules="rules2"
            class="demo-ruleForm"
          >
            <div v-if="activeIndex === 0">
              <el-form-item label="账号" prop="userCode">
                <el-input
                  v-model="loginFrom.userCode"
                  placeholder="请输入登录账号"
                />
              </el-form-item>
              <el-form-item label="密码" prop="password2">
                <el-input
                  v-model="loginFrom.password2"
                  type="password"
                  placeholder="请输入密码"
                />
              </el-form-item>
              <!-- <el-form-item label="验证码" prop="kaptcha" class="kaptcha">
                <el-input
                  v-model="loginFrom.kaptcha"
                  class="verificationCode"
                  placeholder="请输入验证码"
                  clearable
                />
                <img
                  :src="loginFrom.codeimg"
                  alt
                  class="imageCode"
                  @click="getVerificationCode()"
                >
              </el-form-item> -->
              <slide-verify :refresh-slide="refreshSlide" @moveCheck="moveCheck" />
            </div>
            <div v-else>
              <el-form-item class="account2" label="手机号码" prop="phoneNo">
                <el-input
                  v-model="loginFrom.phoneNo"
                  placeholder="请输入手机号"
                />
              </el-form-item>
              <el-form-item class="password2" label="验证码" prop="code">
                <el-input
                  v-model="loginFrom.code"
                  type="number"
                  maxlength="6"
                  class="verificationCode"
                  placeholder="请输入验证码"
                />
                <span
                  :class="['code', 'imageCode', disabled ? '' : 'disabledActive']"
                  @click="getCode"
                >{{ codeStr }}</span>
              </el-form-item>
            </div>
            <el-form-item label="" prop="cmpRole">
              <el-radio-group v-model="loginFrom.cmpRole">
                <el-radio label="03">
                  经销商
                </el-radio>
                <el-radio label="04">
                  代采商
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="" class="confirmLogin">
              <el-button type="primary" :class="(Object.keys(loginFrom.imageCaptchaTrackReq).length == 0 && activeIndex === 0) ? 'prohibit': 'operate'" :loading="$store.state.loading" @click="goLogin('ruleForm')">
                登 录
              </el-button>
            </el-form-item>
            <div class="jump">
              <router-link class="backLogin" :to="{ path: '/register' }">
                无账号？<span>去注册</span>
              </router-link>
            </div>
          </el-form>
          <span class="circular-one" />
          <span class="circular-two" />
          <span class="circular-three" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodes, login, sendCodeLogin, phoneLogin } from '@/http/agentApi/login'
import SlideVerify from '@/components/SlideVerify.vue'
import generateCanvas from '@/assets/js/canvas.js'
import { rules } from '@/unit/matchFormRegex'
import { encrypt } from '@/unit/index'
export default {
  components: { SlideVerify },
  data() {
    return {
      loginOrRegister: false,
      agreeProtocol: false,
      codeStr: '获取验证码',
      ruleForm: {},
      loginFrom: { userCode: '', password2: '', kaptcha: '', codeimg: '', code: '', phoneNo: '', cmpRole: '03', imageCaptchaTrackReq: {}},
      disabled: true,
      loginTabArr: ['账号密码登录', '手机号登录'],
      activeIndex: 0,
      rules2: {
        phoneNo: [...rules.phone],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ],
        kaptcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度应为 4 个字符', trigger: 'blur' }
        ],
        cmpRole: [{ required: true, message: '请选择', trigger: 'change' }],
        password2: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        userCode: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          {
            min: 5,
            max: 25,
            message: '长度在 5 到 25 个字符',
            trigger: 'blur'
          }
        ]
      },
      refreshSlide: null
    }
  },
  mounted() {
    generateCanvas.circleBottom(true)
    // this.getVerificationCode()
    this.$nextTick(() => {
      generateCanvas.circleBottom()
    })
  },
  destroyed() {
    generateCanvas.circleBottom(true)
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.timer) return
      let num = 60
      if (!this.loginFrom.phoneNo || this.loginFrom.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      sendCodeLogin(this.loginFrom.phoneNo, (res) => {
        this.loginFrom.code = res.msg
        this.disabled = false
        this.timer = setInterval(() => {
          if (num <= 1) {
            clearInterval(this.timer)
            this.timer = null
            this.codeStr = '获取验证码'
            this.disabled = true
            return
          }
          num--
          this.codeStr = num >= 10 ? num + 's' : '0' + num + 's'
        }, 1000)
      })
    },
    // 点击登录呀
    goLogin(formName) {
      if (Object.keys(this.loginFrom.imageCaptchaTrackReq).length === 0 && this.activeIndex === 0) return
      // 先存一下平台的角色了
      this.$store.commit('changeSystemPlatformRoles', this.loginFrom.cmpRole)
      // 登录之前把注册置空
      // 03是经销商  04是代采商
      switch (this.loginFrom.cmpRole) {
        case '03':
          sessionStorage.setItem('tokenCust', null)
          this.$store.commit('getToken', null)
          break
        case '04':
          sessionStorage.setItem('tokenAgent', null)
          this.$store.commit('getToken', null)
          break
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.activeIndex === 0) { this.loginFrom.password = encrypt(this.loginFrom.password2) }
          const api = this.activeIndex === 0 ? login : phoneLogin
          const param = JSON.parse(JSON.stringify(this.loginFrom))
          delete param.password2
          api(param, (res) => {
            this.$store.commit('getToken', res.data.token)
            this.loginFrom.cmpRole === '03' ? localStorage.setItem('userInfoCust', JSON.stringify(res.data)) : localStorage.setItem('userInfoAgent', JSON.stringify(res.data))
            if (res.data.cmpState === '01') {
              this.$message.success(
                '您的基本信息暂未录入完毕，请先补齐基本信息！'
              )
            }
            switch (res.data.cmpState) {
              case '01':
                this.$router.push({
                  path: '/enterpriseInformation/addInformation'
                })
                break
              default:
                this.$router.push({ path: '/' })
                break
            }
          }, () => {
            // this.getVerificationCode()
            this.refreshSlide = String(new Date())
            this.ruleForm.imageCaptchaTrackReq = {}
          })
        }
      })
    },
    getActiveIndex(index) {
      this.activeIndex = index
      this.$refs['ruleForm'].resetFields()
    },
    // 获取验证码
    getVerificationCode() {
      getCodes((res) => {
        this.loginFrom.codeimg = res.captchaBase64
        this.loginFrom.key = res.key
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 获取校验数据
    moveCheck(data) {
      this.loginFrom.imageCaptchaTrackReq = data
    }
  }
}
</script>

<style lang="scss">
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
.loginPageBox {
  background: url("../../../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 1400px;
  overflow: hidden;
  .contentBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loginTitle{
      color: #fff;
      font-size: 42px;
      font-weight: 500;
    }
    .loginDescription{
      color: #fff;
      font-size: 32px;
      font-weight: 500;
      padding-bottom: 48px;
    }
  }
  .form {
    display: flex;
    width: 50vw;
    width: 960px;
    height: 54vh;
    min-height: 505px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 20px;

    .form-left {
      width: 50%;
      background-color: #dd2629;
      border-radius: 20px 0 0 20px;
      position: relative;
      overflow: hidden;
      .logo {
        width: 386px;
        height: 114px;
        object-fit: contain;
        position: absolute;
        left: 0;
        right: 0;
        top: 160px;
        margin: auto;
      }
      .graphical {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: url("../../../assets/image/04.png") no-repeat top center;
        transform: scale(1.1);
      }
      .canvas {
        position: absolute;
        bottom: -50px;
        left: 0;
      }
    }

    .form-right {
      width: 50%;
      padding: 40px 62px;
      box-sizing: border-box;
      position: relative;
      .titleTab {
        padding: 0 35px 35px;
        width: calc(100% - 70px);
        display: flex;
        z-index: 12;
        span {
          position: relative;
          color: #747774;
          font-size: 16px;
          flex: 1;
          text-align: center;
          cursor: pointer;
        }

        span::after {
          position: absolute;
          left: 50%;
          transform: translatex(-50%);
          bottom: -11px;
          width: 24px;
          height: 2px;
          content: "";
        }

        .active {
          color: #e1251b;
        }

        .active::after {
          background-color: #e1251b;
        }
      }
      .shfit {
        padding-top: 24px;
      }

      .circular-one {
        display: inline-block;
        background: url("../../../assets/image/03.png") no-repeat top left;
        background-size: 100% 100%;
        width: 85px;
        height: 77px;
        position: absolute;
        left: 20px;
        top: 24px;
      }

      .circular-two {
        display: inline-block;
        background: url("../../../assets/image/01.png") no-repeat top left;
        background-size: 100% 100%;
        width: 122px;
        height: 108px;
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .circular-three {
        display: inline-block;
        background: url("../../../assets/image/02.png") no-repeat top left;
        background-size: 100% 100%;
        width: 143px;
        height: 168px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .el-form-item {
        margin-bottom: 12px;
      }
      .el-form-item__label {
        font-size: $mainFontSize;
        float: none;
        text-align: left;
        display: block;
        color: $mainFontColor;
        font-weight: 500;
        font-family: PingFang SC;
        line-height: 32px;
      }
    }

    .reigster-form {
      padding: 20px 50px;
      .login-title {
        margin-bottom: 10px;
      }
      .el-form-item__content {
        line-height: 28px;
      }
      .el-form-item {
        .el-input__inner {
          height: 28px;
        }
      }
      .el-form-item__label {
        float: left;
        height: 32px;
      }
      .agreement {
        margin: 12px 0;
      }
      .register-btn {
        padding: 0 10%;
        .el-form-item__content {
          display: flex;
          justify-content: space-between;
        }
        .el-button {
          width: 48%;
          border-radius: 34px;
        }
        .el-form-item__content {
          text-align: center;
        }
        .determine {
          border-color: $assistColor5;
          background-color: $assistColor5;
        }
      }
    }

    .confirmLogin {
      text-align: center;
      margin-top: 12px;
      .el-button--primary {
        width: 80%;
        padding: 10px 0;
        background-color: $assistColor5;
        border-radius: 34px;
        border-color: $assistColor5;
        &.prohibit {
          cursor: default;
          background-color: rgba(0,0,0,.2);
          border-color: rgba(0,0,0,0);
        }
      }
    }
    .el-input__inner {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(63, 63, 63, 0.3);
      color: #000000;
      font-size: $mainFontSize;
      &:hover {
        border-bottom: 1px solid rgba(63, 63, 63, 0.9);
      }
      ::placeholder {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .agreement {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px 0;
      .el-checkbox {
        color: $mainFontColor;
        .el-checkbox__label {
          font-size: 12px;
          color: rgb(85, 83, 83);
        }
      }
    }

    .jump {
      text-align: center;
      position: absolute;
      width: 80%;
      bottom: 20px;
      .backLogin {
        font-size: $mainFontSize;
        color: #999999;
        cursor: pointer;

        span {
          color: $assistColor5;
        }
      }
    }

    .verificationCode {
      display: inline-block;
      width: calc(100% - 120px);
    }
    .imageCode {
      display: inline-block;
      text-align: center;
      background-color: $mainBlue;
      height: 40px;
      width: 100px;
      margin-left: 20px;
      cursor: pointer;
      vertical-align: bottom;
      color: #fff;
    }
    .getCode {
      display: inline-block;
      height: 100%;
      width: 100px;
      margin-left: 20px;
      border: 0;
      outline: 0;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      background-color: #f8af00;
    }
    .disabledActive {
      background-color: #ccc;
      color: #f9f9f9;
    }
  }

  .el-checkbox__input {
    &.is-checked .el-checkbox__inner {
      border-color: $assistColor5;
      background-color: $assistColor5;
    }
    &.is-focus .el-checkbox__inner {
      border-color: $assistColor5;
    }
    .el-checkbox__inner:hover {
      border-color: $assistColor5;
    }
  }
}

// .kaptcha .el-form-item__label {
//   margin-left: 12px;
// }
</style>
